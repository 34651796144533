import React, { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppNavigation } from './constants/appNavigation';
import { Layout } from './layout';
import { ConfigureProviders } from './core/providers';

const App: React.FC = () => {
  // SETUP LAZY LOADING
  const LoginPage = lazy(() => import('./pages/LoginPage'));
  const DomainsPage = lazy(() => import('./pages/DomainsPage'));
  const StatusesPage = lazy(() => import('./pages/StatusesPage'));
  const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
  const UsersPage = lazy(() => import('./pages/UsersPage'));

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ConfigureProviders />}>
          <Route path={'/'} element={<Navigate to={AppNavigation.LOGIN} />} />
          <Route
            path={AppNavigation.LOGIN}
            element={
              <React.Suspense>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route
            path={'pages'}
            element={<Navigate to={AppNavigation.DOMAINS} />}
          />
          <Route element={<Layout />}>
            <Route
              path={'*'}
              element={
                <React.Suspense>
                  <NotFoundPage />
                </React.Suspense>
              }
            />
            <Route
              path={AppNavigation.USERS}
              element={
                <React.Suspense>
                  <UsersPage />
                </React.Suspense>
              }
            />
            <Route
              path={AppNavigation.STATUSES}
              element={
                <React.Suspense>
                  <StatusesPage />
                </React.Suspense>
              }
            />
            <Route
              path={AppNavigation.DOMAINS}
              element={
                <React.Suspense>
                  <DomainsPage />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
