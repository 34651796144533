import React from 'react';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { CurrentUserContext } from './CurrentUserContext';

interface Props {
  children: React.ReactNode;
}

export const CurrentUserProvider: React.FC<Props> = ({ children }) => {
  const currentUser = useCurrentUser();
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};
