import { useCallback } from 'react';
import { ApiRoutes } from '../constants/apiRoutes';
import { useNavigate } from 'react-router-dom';
import { AppNavigation } from '../constants/appNavigation';
import { useAxiosContext } from '../core/providers/axios';
import { useLocalStorage } from './useLocalStorage';

export const useLogout = (): (() => void) => {
  const [, , , clearLocalStorge] = useLocalStorage();
  const axios = useAxiosContext();
  const navigate = useNavigate();

  const logout = useCallback(
    () =>
      axios &&
      axios
        .get(ApiRoutes.LOGUT)
        // ERROR HANDLING PROVIDED IN AXIOS PROVIDER
        .catch(() => {})
        // CLEAR LOCAL STORAGE AND NAVIGATE USER TO LOGIN PAGE ANYWAY
        .finally(() => {
          clearLocalStorge();
          navigate(AppNavigation.LOGIN);
        }),
    [axios]
  );

  return logout;
};
