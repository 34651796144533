export const ApiRoutes = {
  // AUTH
  LOGIN: 'auth/login',
  LOGUT: 'auth/logout',
  // ADMIN ROUTES
  USERS: 'user',
  // USER ROUTES
  DOMAIN: 'domain',
  STATUSES: 'status',
  CURRENT_USER: 'user/current',
} as const;
