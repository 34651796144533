import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#63b8ff',
      main: '#0989e3',
      dark: '#005db0',
    },
    secondary: {
      main: '#D74444',
      light: '#82e9de',
      dark: '#00867d',
    },
  },
  zIndex: {
    drawer: 998,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '64px',
        },
      },
    },
    // DISABLING AUTOCOMPLETE
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #121212 inset',
              WebkitTextFillColor: '#fff',
            },
          },
        },
      },
    },
  },
});
