import React, { useEffect, useState, useMemo } from 'react';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ColorModeContext } from './ColorModeContext';
import { ThemeProvider } from '@emotion/react';
import { darkTheme, lightTheme } from '../../mui';
import { CssBaseline } from '@mui/material';

type Mode = 'light' | 'dark';

interface Props {
  children: React.ReactNode;
}

export const ColorModeProvier: React.FC<Props> = ({ children }) => {
  const [getItem, setItem] = useLocalStorage();
  const [mode, setMode] = useState<Mode>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          setItem('colorMode', newMode);
          return newMode;
        });
      },
    }),
    []
  );

  useEffect(() => {
    const existedColorMode = getItem('colorMode');
    setMode(existedColorMode || 'light');
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
        {/* RESETING DEFAULT CSS STYLES */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
