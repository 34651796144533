import { useContext } from 'react';
import { CurrentUserContext } from './CurrentUserContext';

export const useCurrentUserContext = () => {
  const value = useContext(CurrentUserContext);
  if (!value) {
    throw new Error('useCurrentUserContext used outside of CurrentUserContext');
  }
  return value;
};
