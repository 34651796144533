import { Box, IconButton, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useLocalStorage } from './useLocalStorage';

// PWA INSTALING BTN LOGIC
export const InstallPWA: React.FC = () => {
  const [getItem, setItem] = useLocalStorage();
  const [show, setShow] = useState(false);
  const [promptInstall, setPromptInstall] = useState<unknown>(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setShow(true);
      setPromptInstall(e);
    };
    const disabled = getItem('disableInstallButton');
    if (!disabled) {
      window.addEventListener('beforeinstallprompt', handler);
    }

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    disableInstallButton();
    if (promptInstall) {
      (promptInstall as { prompt: () => void }).prompt();
    }
  };

  const disableInstallButton = () => {
    setShow(false);
    setItem('disableInstallButton', true);
  };

  if (!show) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="body1">This app could be installed</Typography>
      <IconButton color="primary" onClick={onClick}>
        <InstallDesktopIcon />
      </IconButton>
      <IconButton sx={{ color: 'red' }} onClick={disableInstallButton}>
        <CancelOutlinedIcon />
      </IconButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(
  ({ theme }) => `
    &.MuiBox-root {
        position: fixed;
        bottom: 60px;
        left: 0;
        right: 0;
        width: fit-content;
        margin: auto;
        padding: 2px 5px;
        z-index: 999;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 10px;
        background: ${theme.palette.background.default};
    }
`
);
