import { useEffect, useMemo, useState } from 'react';
import { CurrentUser, WithRoleUser } from '../shared/interfaces/user.interface';
import { ApiRoutes } from '../constants/apiRoutes';
import { useLocation } from 'react-router-dom';
import { useAxiosContext } from '../core/providers/axios';

export const useCurrentUser = (): CurrentUser => {
  const axios = useAxiosContext();
  const location = useLocation();
  // SETUP INITIAL ROLE === ADMIN TO PREVENT ACTUAL ADMIN REDIRECTING FROM USERS PAGE
  const emptyUser: WithRoleUser = useMemo(
    () => ({
      team: '',
      id: '',
      username: '',
      role: 'ADMIN',
    }),
    []
  );
  const [user, setUser] = useState<WithRoleUser>(emptyUser);
  const [loading, setLoading] = useState(false);
  const [userReceived, setUserReceived] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    if (axios && location.pathname !== '/login' && !userReceived) {
      setLoading(true);
      let errName = '';
      axios(ApiRoutes.CURRENT_USER, {
        method: 'GET',
        signal: abortController.signal,
      })
        .then((res) => {
          setUserReceived(true);
          setUser(res.data);
        })
        .catch((error) => {
          setUserReceived(false);
          errName = error.name;
          if (errName !== 'CanceledError') {
            setUser({
              ...emptyUser,
              username: 'user fetching error',
              role: 'USER',
            });
          }
        })
        .finally(() => {
          if (errName && errName === 'CanceledError') {
            return;
          }
          setLoading(false);
        });
    }

    if (location.pathname === '/login') {
      setUser({ ...emptyUser, role: 'USER' });
      setUserReceived(false);
      setLoading(false);
    }

    return () => abortController.abort();
  }, [axios, location]);

  return { user, loading };
};
